class MenuSearch {
  constructor() {
    this.searchButton = document.querySelector(".menu-search-button a");
    this.searchBoxContainer = document.querySelector(".search-box-container");
    this.closeButton = document.querySelector(".close-search-button");
    if (this.searchButton) {
      this.init();
    }
  }

  init() {
    this.searchButton.addEventListener("click", e => {
      e.preventDefault();
      this.searchBoxContainer.classList.add("open");
    });

    this.closeButton.addEventListener("click", () => {
      this.searchBoxContainer.classList.remove("open");
    });
  }
}

new MenuSearch();
